<div class="container">
    <div class="content-wrapper">
        <div class="row">
            <div class="col-8 col-xs-12">
                <div class="card">
                    <div class="row">
                        <div class="col-5 col-xs-12">
                            <div class="card-inner">
                                <h1>Bachelor of Science in Interdisciplinary Studies</h1>
                                <p>The Bachelors in Interdisciplinary Studies program provides
                                    students with an opportunity to create flexible career options
                                    through the use of “emphases” drawn from other Walden University
                                    degree programs to obtain an understanding and the skills needed
                                    in professions of interest.</p>
                            </div>
                        </div>
                        <div class="col-1"></div>
                        <div class="col-6  col-xs-12">
                            <div class="card-right-inner">
                                <h4>CLASSES</h4>
                                <div class="flex-inner" *ngFor="let items of courseDetails | slice:0:5"
                                    [ngClass]="{'cursorDisable': !items.isActive}">
                                    <a class="flex-inner-container" href="{{ items.url }}" target="_blank" (click)="trackActivity('CLASS',items.title)"
                                        [ngClass]="{'disableButton': !items.isActive}">
                                        <span *ngIf="items.isActive"> {{ items.title }} </span>
                                        <span *ngIf="!items.isActive" class="inactive-class-details"> {{ items.title }} <br><a>will be available on {{ items.startDate }}</a> </span>
                                        <img src="../../../../assets/img/ic_arrowBlue.svg" alt="arrow-icon">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- webinar-card -->

                <div class="card webinar-card">
                    <div class="heading">
                        Webinar
                    </div>

                    <div class="learn-more">
                        <a href="https://webinars.on24.com/waldenu/20200828SSWZB1630" target="_blank"style="
                        font-weight: bold;
                        color: #f0f3f7;
                        font-size: 1.0625rem;
                        font-weight: bold;
                        text-decoration: none;
                        cursor: pointer;
                    " >
                        LEARN MORE
                        <span>
                            <img src="../../../../assets/img/ic_arrowWhite (1).svg" alt="arrow-icon">
                        </span>
                    </a>
                    </div>
                </div>

                <!-- Check your Finances && Request Financial Aid -->

                <div class="finance-cards">
                    <div class="row">
                        <div class="col-6  col-xs-12">
                            <div class="card">
                                <div class="card-inner">
                                    <h1>Check your Finances</h1>
                                    <p>Review your student account, make a payment, or set up payment
                                        arrangements through the student finance portal.</p>
                                    <div class="explore-all">
                                        <a href="{{myFinanceUrl}}" target="_blank" (click)="trackActivity('FINANCE PORTAL','')">FINANCE PORTAL
                                            <span>
                                                <img src="../../../../assets/img/ic_arrowBlue.svg" alt="arrow-icon">
                                            </span>
                                        </a>

                                    </div>
                                </div>
                            </div>
                            <div class="card billing-card">
                                <div class="card-inner">
                                    <a href="{{financeAidBillingUrl}}" target="_blank" class="hide-decorator" (click)="trackActivity('FINANCIAL AID & BILLING SUPPORT','')"
                                        rel="noopener noreferrer">
                                        <div class="finance-detail">
                                            <div class="heading">FINANCIAL AID & BILLING SUPPORT</div>
                                            <div class="arrow-icon">
                                                <img src="../../../../assets/img/ic_arrowBlue.svg" alt="arrow-btn-icon">
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-6  col-xs-12">
                            <div class="card financial-aid-status-card">
                                <div class="card-inner">
                                    <h1>Financial Aid Status</h1>
                                    <div class="fafsa-container">
                                        <p>Complete the 2020-2021 Free Application for Federal Student Aid.
                                        </p>
                                        <div class="explore-all">
                                            <a href="{{fafsaUrl}}" target="_blank" (click)="trackActivity('LINK TO FAFSA','')">LINK TO FAFSA
                                                <span>
                                                    <img src="../../../../assets/img/ic_arrowBlue.svg" alt="arrow-icon">
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <p>Track the status of your Financial Aid and review any notifications.</p>
                                    <div class="explore-all">
                                        <a href="{{myFinanceAIDUrl}}" target="_blank" (click)="trackActivity('FINANCIAL AID','')">FINANCIAL AID
                                            <span>
                                                <img src="../../../../assets/img/ic_arrowBlue.svg" alt="arrow-icon">
                                            </span>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





            </div>
            <div class="col-4 col-xs-12">

                <!-- Grade card -->
                <a class="grade-card-wrapper" href="{{finalGradesUrl}}" target="_blank" (click)="trackActivity('My Grades','')">
                    <div class="grade-card">
                        <div class="card">
                            <div class="card-inner">
                                <a class="hide-decorator">
                                    <div class="finance-detail">
                                        <div class="heading">My Grades</div>
                                        <div class="arrow-btn"><img src="../../../../assets/img/ic_arrowGrades.svg"
                                                alt="arrow-icon"></div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </a>

                <!-- degree cards -->

                <div class="degree-cards">
                    <div class="row">
                        <div class="col-4 col-xs-8">
                            <div class="hvr-grow card">
                                <div class="card-inner">
                                    <a href="{{unofficialTranscriptUrl}}" target="_blank" class="hide-decorator" (click)="trackActivity('Unofficial Transcripts','')">
                                        <img src="../../../../assets/img/ic_unoffTranscripts.svg"
                                            alt="inofficial-transcript-logo">
                                        <h4> Unofficial Transcripts</h4>
                                    </a>
                                    <p>Request a copy of your unofficial transcript.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 col-xs-8">
                            <div class="hvr-grow card">
                                <div class="card-inner">
                                    <a href="{{degreeAuditUrl}}" target="_blank" class="hide-decorator" (click)="trackActivity('Degree Audit','')">
                                        <img src="../../../../assets/img/ic_degreeAudit.svg" alt="degree-audit-logo">
                                        <h4>Degree Audit</h4>
                                    </a>
                                    <p>Review your degree requirements and academic progress.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 col-xs-8">
                            <div class="hvr-grow card">
                                <div class="card-inner">
                                    <a href="{{officialTranscriptUrl}}" target="_blank" class="hide-decorator" (click)="trackActivity('Official Transcripts','')">
                                        <img src="../../../../assets/img/ic_unoffTranscripts.svg"
                                            alt="official-transcript-logo">
                                        <h4> Official Transcripts</h4>
                                    </a>
                                    <p>Request a copy of your official transcript.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- mentor card -->

                <div class="mentor-card">
                    <div class="card">
                        <div class="card-inner">
                            <div class="heading">
                                Meet your Advisor
                            </div>
                            <div class="advisor-content" *ngIf="advisorDetails">
                                <div class="advisor-logo">
                                    <img *ngIf="!advisorDetails.imageUrl" [src]="defaultImageUrl" alt="advisor-logo">
                                    <img *ngIf="advisorDetails.imageUrl" [src]="advisorDetails.imageUrl"
                                        alt="advisor-logo">
                                </div>
                                <div class="advisor-details">
                                    <div class="advisor-name">{{advisorDetails.firstName}} {{advisorDetails.lastName}}
                                    </div>
                                    <div class="advisor-phno">{{ advisorDetails.phone }}</div>
                                    <div class="advisor-email">studentsuccess@mail.waldenu.edu</div>
                                    <!-- {{ advisorDetails.email }}-->
                                </div>
                            </div>
                            <div class="schedule-appointment">
                                <a [href]="scheduleAppointmentUrl" target="_blank" (click)="trackActivity('SCHEDULE AN APPOINTMENT','')">
                                    <div class="appointment-box">
                                        <div class="schedule-heading">SCHEDULE AN APPOINTMENT</div>
                                        <div class="schedule-arrow-btn">
                                            <img src="../../../../assets/img/ic_arrowBlue.svg" alt="arrow-btn-icon">
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- help and support card -->

                <div class="help-support-card">
                    <div class="card">
                        <div class="card-inner">
                            <div class="help-support-box">
                                <div class="line-icon">
                                    <img src="../../../../assets/img/ic_panelHandle.svg" alt="line-icon">
                                </div>
                                <div class="heading-content">
                                    <div class="chat-logo">
                                        <img src="../../../../assets/img/ic_supportChat.svg" alt="chat-icon">
                                    </div>
                                    <div class="chat-heading">
                                        Help & Support
                                    </div>
                                </div>
                                <div class="live-chat">
                                    <div id="{{ 'liveagent_button_online_' + liveChatInItParam}}" 
                                    class="live-chat-box cursorEnable"
                                        style="display: none;" (click)="startLiveAgent()">
                                        <div class="live-chat-heading">
                                            Customer Care <span>Tech Support</span>
                                        </div>
                                        <div id="online" class="live-chat-status">
                                            <span class="dot online"></span>
                                            <span id="online">ONLINE</span>
                                        </div>
                                    </div>
                                    <div id="{{ 'liveagent_button_offline_' + liveChatInItParam}}" 
                                        class="live-chat-box cursorDisable" style="display: none;">
                                        <div class="live-chat-heading">
                                            Customer Care <span>Tech Support</span>
                                        </div>
                                        <div id="offline" class="live-chat-status">
                                            <span class="dot offline"></span>
                                            <span id="offline">OFFLINE</span>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="customer-care-policy">
                                    Clicking in the button category constitutes your express witten consent to be
                                    contacted by email, phone, text, and prerecorded message by Walden at the number(s)
                                    you provided, regarding furthering your education. You understand and agree that
                                    these calls may be generated using an automated technology. Privacy Policy
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>