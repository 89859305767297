import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';
import { userClasses } from '../../components/apollo/queries/types/userClasses';
import { GET_USER_CLASSES } from '../../components/apollo/queries/userClasses';
import { USER_INFO_QUERY, ADVISOR_INFO_QUERY } from '../../components/apollo/queries/userInfo';
import { userInfoQuery } from '../../components/apollo/queries/types/userInfoQuery';
import {academicAdvisorQuery} from '../../components/apollo/queries/types/academicAdvisorQuery';

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {

  constructor(private apollo: Apollo, ) { }

  /**
   * @description get user details based on email
   * @method getUserDetails
   * @param {string} email
   * @returns user details
   */
  getUserDetails(email: string): Observable<ApolloQueryResult<userInfoQuery>> {
    return this.apollo.watchQuery<userInfoQuery>({
      query: USER_INFO_QUERY,
      variables: { email }
    }).valueChanges;
  }

  /**
   * @description get advisor details based on email
   * @method getAdvisorDetails
   * @param {string} email
   * @returns advisor details
   */
  getAdvisorDetails(email: string): Observable<ApolloQueryResult<academicAdvisorQuery>> {
    return this.apollo.watchQuery<academicAdvisorQuery>({
      query: ADVISOR_INFO_QUERY,
      variables: { email }
    }).valueChanges;
  }

  /**
   * @description get user courses based on email
   * @method getUserCourses
   * @param {string} email
   * @returns user courses
   */
  getUserCourses(email: string): Observable<ApolloQueryResult<userClasses>> {
    return this.apollo.watchQuery<userClasses>({
      query: GET_USER_CLASSES,
      variables: { email }
    }).valueChanges;
  }

}
