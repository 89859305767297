import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CloudService } from '../../../services/cloud/cloud.service';

declare var liveagent: any;

@Component({
  selector: 'app-dashboard-content',
  templateUrl: './dashboard-content.component.html',
  styleUrls: ['./dashboard-content.component.scss']
})
export class DashboardContentComponent implements OnInit {

  @Input() public courseDetails: any;
  @Input() public advisorDetails: any;
  @Input() public currentUser: any;
  public showCard: boolean;

  public blackBoardUrl = environment.backBoardUrl;
  public myFinanceUrl = environment.myFinanceUrl;
  public myFinanceAIDUrl = environment.myFinanceAIDUrl;
  public unofficialTranscriptUrl = environment.unofficialTranscriptUrl;
  public officialTranscriptUrl = environment.officialTranscriptUrl;
  public degreeAuditUrl = environment.degreeAuditUrl;
  public finalGradesUrl = environment.finalGradesUrl;
  public defaultImageUrl = `${environment.assetsUrl}/img/avatar.png`;
  public scheduleAppointmentUrl = environment.scheduleAppointmentUrl;
  public fafsaUrl = environment.fafsaLink;
  public financeAidBillingUrl = environment.financeAidBillingUrl;
  public showNumbers = false;
  public liveChatInItParam = environment.liveAgentUrlData;

  constructor(private readonly cloudService: CloudService) { }

  ngOnInit() {
    const scriptUrl = environment.liveAgentScriptUrl;
    const node = document.createElement('script');
    node.src = scriptUrl;
    node.type = 'text/javascript';
    node.onload = () => {
      liveagent.showWhenOnline(this.liveChatInItParam, document.getElementById(`liveagent_button_online_${this.liveChatInItParam}`));
      // tslint:disable-next-line: max-line-length
      liveagent.showWhenOffline(this.liveChatInItParam, document.getElementById(`liveagent_button_offline_${this.liveChatInItParam}`));
      liveagent.init(environment.liveAgentUrl, environment.liveAgentInitParam1, environment.liveAgentInitParam2);
    };
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  /**
   * @description Start chat if agent is available.
   * @method startLiveAgent
   * @returns chat info
   */ 
  startLiveAgent() {
    const status =
      { activity: 'OPEN LIVE CHAT', message: `Started Live Chat for ${this.currentUser.displayName}`, userId: this.currentUser.identifier };
    this.addToStack(status);
    liveagent.startChat(this.liveChatInItParam);
  }

  /**
   * @description This method is used to track user activity
   * @method trackActivity
   * @param {string} type
   * @param {any} data
   */ 
  trackActivity(type: string, data: any) {
    let status: any;
    if (type === 'CLASS') {
      status =
      {
        activity: 'CLASS SELECTED',
        message: `${this.currentUser.displayName} selected class '${data}'`,
        userId: this.currentUser.email
      };
    }
    if (type === 'FINANCE PORTAL') {
      status =
      {
        activity: 'FINANCE PORTAL',
        message: `${this.currentUser.displayName} clicked Finance Portal`,
        userId: this.currentUser.email
      };
    }
    if (type === 'FINANCIAL AID & BILLING SUPPORT') {
      status =
      {
        activity: 'FINANCIAL AID & BILLING SUPPORT',
        message: `${this.currentUser.displayName} clicked Financial Aid & Billing Support`,
        userId: this.currentUser.email
      };
    }
    if (type === 'LINK TO FAFSA') {
      status =
      {
        activity: 'LINK TO FAFSA',
        message: `${this.currentUser.displayName} clicked Link to FAFSA`,
        userId: this.currentUser.email
      };
    }
    if (type === 'FINANCIAL AID') {
      status =
      {
        activity: 'FINANCIAL AID',
        message: `${this.currentUser.displayName} clicked Financial Aid`,
        userId: this.currentUser.email
      };
    }
    if (type === 'My Grades') {
      status =
      {
        activity: 'My Grades',
        message: `${this.currentUser.displayName} clicked My Grades`,
        userId: this.currentUser.email
      };
    }
    if (type === 'Unofficial Transcripts') {
      status =
      {
        activity: 'Unofficial Transcripts',
        message: `${this.currentUser.displayName} clicked Unofficial Transcripts`,
        userId: this.currentUser.email
      };
    }
    if (type === 'Degree Audit') {
      status =
      {
        activity: 'Degree Audit',
        message: `${this.currentUser.displayName} clicked Degree Audit`,
        userId: this.currentUser.email
      };
    }
    if (type === 'Official Transcripts') {
      status =
      {
        activity: 'Official Transcripts',
        message: `${this.currentUser.displayName} clicked Official Transcripts`,
        userId: this.currentUser.email
      };
    }
    if (type === 'SCHEDULE AN APPOINTMENT') {
      status =
      {
        activity: 'SCHEDULE AN APPOINTMENT',
        message: `${this.currentUser.displayName} clicked SCHEDULE AN APPOINTMENT`,
        userId: this.currentUser.email
      };
    }

    this.addToStack(status);
  }


  /**
   * @description This method is used to add status data of user into stack.
   * @method addToStack
   * @param {any} statusData
   */ 
  addToStack(statusData: any) {
    this.cloudService.postDataToStack(statusData, (response: any) => { }, (error: any) => { console.log(error); });
  }

}


