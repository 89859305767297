// Import Observable
import { Observable } from 'rxjs';

// Import Firebase and AngularFire
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // The authentiction information of the user signed in.
  public authInfo: Observable<firebase.User>;

  constructor(
    private afAuth: AngularFireAuth,
  ) {
    this.authInfo = this.afAuth.authState;
  }

  /**
   * @description method to authenticate the user based on the service selected.
   * Note: The authentication service can be anything. (microsoft.com, google.com... etc).
   * It can be configured.
   * @method login
   * @param successCallback
   * @returns void
   */
  login(successCallback) {
    var provider = new firebase.auth.OAuthProvider(environment.authService);
    provider.setCustomParameters({
      tenant: environment.tenandId,
      domain_hint: environment.domainHint
    });
    this.afAuth.auth.signInWithRedirect(provider)
      .then((user) => {
        successCallback(user);
      });
  }


  /**
   * @description method to logout the user based on the service selected.
   * @method logout
   * @returns void
   */
  logout(successsCallback) {
    this.afAuth.auth.signOut().then(() => { 
      successsCallback(true);
    });
  }
}
