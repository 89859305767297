import gql from 'graphql-tag';


export const USER_INFO_QUERY = gql`
	query userInfoQuery($email: String!) {
		user(email: $email) {
			info {
				identifier
				username
				ext_laur_preferred_name
				email
				ext_laur_country
				ext_laur_is_international
				ext_laur_has_financial_aid
				ext_laur_is_military
				ext_laur_country
				sms
				laureateId
				userId
				givenName
				familyName
				role
				phone
				userRole
				firstLogin
				neverShowItAgain
            }
            academicAdvisor {
				studentSourcedId
				firstName
				lastName
				directPhone
				phone
				phoneExt
				email
			}
		}
	}
`;

export const ADVISOR_INFO_QUERY = gql`
query userInfoQuery($email: String!) {
	user(email: $email) {
		academicAdvisor {
			studentSourcedId
			firstName
			lastName
			directPhone
			phone
			phoneExt
			email
		}
	}
}
`;
