import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, concat } from 'apollo-link';
import { environment } from '../../../environments/environment';


@NgModule({
  exports: [
    HttpClientModule,
    ApolloModule,
    HttpLinkModule
  ]
})
export class GraphQLModule {
  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink
  ) {
    const http = this.httpLink.create({ uri: `${environment.unifiedPortalGraphQl.baseUrl}/learn/1.0/info` });
    const cache = new InMemoryCache();
    const authMiddleware = new ApolloLink((operation, forward) => {
      const token = sessionStorage.getItem('auth-token');
      const audience = addAudienceToToken(token) || null;
      operation.setContext({
        headers: {
          Audience: audience,
          AuthenticationType: environment.unifiedPortalGraphQl.type,
          'Ocp-Apim-Subscription-Key': environment.unifiedPortalGraphQl.subscriptionKey,
          'content-type': 'application/json',
          'Ocp-Apim-Trace': true,
        }
      });
      return forward(operation);
    });

    const addAudienceToToken = (token: string) => {
      if (token) {
        return `Bearer ${token}`;
      } else {
        console.error('Missing graph_access_token');
        return;
      }
    };
    this.apollo.create({
      link: concat(authMiddleware, http),
      cache
    });
  }
}
