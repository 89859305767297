<div *ngIf="currentUser" class="container-fluid">

    <app-header
        [studentName]="currentUser?.displayName" 
        [studentImage]="currentUser?.imageUrl"
        [studentId]="currentUser?.identifier"
        (openPopup)="openPopupFunc($event)"
        >
    </app-header>

    <app-dashboard-content
        [currentUser]="currentUser" 
        [courseDetails]="currentUserCourseDetails"
        [advisorDetails]="currentUserAdvisorDetails">
    </app-dashboard-content>

    <app-footer></app-footer>
    <app-drop-down *ngIf="openModal" (popupFlag)="popupActionFunc($event)"></app-drop-down>
</div>


