import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CloudService {

  public baseUrl = environment.cloudFunction.baseUrl;


  constructor(private http: HttpClient) { }

  /**
   * @description method to post data to cloud
   * @method postDataToStack
   * @param {any} data
   * @param {any} successCallback
   * @param {any} failureCallback
   */ 
  public postDataToStack(data: any, successCallback, failureCallback) {
    const apiUrl = `${this.baseUrl}/stack/insertlog`;
    let reqBody: any;
    reqBody = {
      event: data.activity,
      logType: 'INFO',
      appName: 'STUDENT',
      message: {
        data: data.message
      },
      userId: data.userId
    };
    this.http.post(apiUrl, reqBody).subscribe((response) => {
      successCallback(response);
    }, error => {
      failureCallback(error);
    });
  }

}
