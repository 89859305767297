<div class="header-wrapper">
    <div class="container">
        <div class="navbar">
            <div class="logo">
                    <img src="../../../../assets/img/W-logo.svg" alt="university-logo">
            </div>
            <div class="profile">
                <a class="profile-logo" [href]="outlookUrl" target="_blank" (click)="addToStack()">
                    <img src="../../../../assets/img/ic_outlook.svg" alt="outlook-logo">
                </a>
                <div class="profile-content">
                    <div class="profile-details">
                        <h2>{{ studentName }}</h2>
                        <h3>{{ studentId }}</h3>
                    </div>
                    <div class="profile-photo">
                        <img *ngIf="studentImage" class="avatar-logo" [src]="studentImage" alt="avatar-logo">
                        <img *ngIf="!studentImage" class="avatar-logo" [src]="defaultImageUrl" alt="avatar-logo">
                        <span (click)="open()">
                            <img src="../../../../assets//img/ic_drop.svg" alt="drop-down-icon">
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>