import { Component, Input, EventEmitter, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CloudService } from '../../../services/cloud/cloud.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() studentName: string;
  @Input() studentId: string;
  @Input() studentImage: string;
  @Output() openPopup = new EventEmitter<boolean>();
  defaultImageUrl = `${environment.assetsUrl}/img/avatar.png`;
  outlookUrl = `${environment.outlookUrl}`;

  constructor(private readonly cloudService: CloudService) { }

  /**
   * @description this method is used to open the pop up
   * @method open
   * @returns void
   */ 
  open() {
    this.openPopup.emit(true);
  }

  /**
   * @description method is used to add status data of user to stack
   * @method addToStack
   * @returns void
   */ 
  addToStack() {
    const statusData = { activity: 'CHECK EMAIL', message: `${this.studentName} checked Mail`, userId: this.studentId };
    this.cloudService.postDataToStack(statusData, (response: any) => { }, (error: any) => { console.log(error); });
  }

}
