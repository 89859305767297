// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'Dev',
  production: false,
  domainHint: 'dev.waldenu.edu',
  authService: 'microsoft.com',
  tenandId: 'a744b2fc-9cb1-4d03-8ea8-91043d5e1e66',
  firebase: {
    apiKey: "AIzaSyDOEyis_HRvk2OfiHZGoqhrvp4-O-BYa6Q",
    authDomain: "dev-myundergradbridge.waldenu.edu",
    databaseURL: "https://walden-undergradbridge-dev.firebaseio.com",
    projectId: "walden-undergradbridge-dev",
    storageBucket: "walden-undergradbridge-dev.appspot.com",
    messagingSenderId: "833046480312",
    appId: "1:833046480312:web:5feb466c49d2a4a6dc7b4d",
    measurementId: "G-S0FFJ88EC4"
  },

  unifiedPortalGraphQl: {
    baseUrl: 'https://dev-api.laureate.net',
    subscriptionKey: '377f30c804754243b0e70b53e441cbc4',
    type: 'System'
  },
  outlookUrl: 'https://outlook.office.com/mail',
  assetsUrl: 'https://upinstitutionconfigsdev.blob.core.windows.net/unifiedportal-lib/assets',
  userImageUrl: 'https://unifiedportal-docker-api-dev.azurewebsites.net/photo/',
  backBoardUrl: 'https://qa03-01.blackboard.laureate.net/auth-saml/saml/login?apId=_195_1&redirectUrl=https%3A%2F%2Fqa03-01.blackboard.laureate.net%2Fwebapps%2Fblackboard%2Fexecute%2Flauncher%3Ftype%3DCourse%26id%3D',
  myFinanceUrl: 'https://finance-qa.waldenu.edu/portal/ssoauth/',
  myFinanceAIDUrl: 'https://qa-finaid.waldenu.edu.qual/NetPartnerStudent',
  unofficialTranscriptUrl: 'https://sso-qa.waldenu.edu/ssomanager/c/SSB?pkg=bzckotrn.p_showtranscript',
  officialTranscriptUrl: 'https://qa-sso.parchment.com/Shibboleth.sso/Login?entityID=http://auth.laureatelab.net/adfs/services/trust',
  degreeAuditUrl: 'https://sso-qa.waldenu.edu/ssomanager/c/SSB?pkg=laur_bwckcapp.P_SelDefTerm',
  finalGradesUrl: 'https://ssb-student-qa.waldenu.edu/StudentSelfService/ssb/studentGrades?mepCode=USW1',

  fafsaLink: 'https://studentaid.gov/h/apply-for-aid/fafsa',
  financeAidBillingUrl: 'https://www.waldenu.edu/connect/video-and-multimedia/what-to-expect/tuition-and-financial-aid/understanding-the-walden-financial-aid-awarding-process',

  liveAgentScriptUrl: 'https://c.la3-c1cs-ph2.salesforceliveagent.com/content/g/js/48.0/deployment.js',
  liveAgentUrl: 'https://d.la3-c1cs-ph2.salesforceliveagent.com/chat',
  liveAgentUrlData: '5732h000000CaYb',
  liveAgentInitParam1: '5721N00000006SK',
  liveAgentInitParam2: '00D2h0000008gOi',

  scheduleAppointmentUrl: 'https://deployment.my.waldenu.edu/#/schedule-appointment',

  cloudFunction: {
    baseUrl: 'https://us-central1-walden-undergradbridge-dev.cloudfunctions.net/ZBRIDGE/v1/rest'
  }
};
