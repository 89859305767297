import { AppConstants } from './../common/app.constants';
import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase/app';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/common/user.service';
import { GraphqlService } from '../../services/common/graphql.service';
import { DatePipe } from '@angular/common';
import { CloudService } from '../../services/cloud/cloud.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public currentUser: any;
  public env: any;
  public currentUserDetails: any;
  public currentUserCourseDetails: any;
  public currentUserAdvisorDetails: any;
  public openModal: boolean;
  public activationDate: any;
  public logoutUrl = AppConstants.LOGOUT_URL;

  constructor(
    private readonly datePipe: DatePipe,
    private readonly userService: UserService,
    private readonly graphqlService: GraphqlService,
    private readonly cloudService: CloudService) { }
  
  /**
   * @description method executed after component is initialized
   * @method ngOnInit
   * @returns void
   */ 
  ngOnInit() {
    this.env = environment;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.currentUser = user;
        firebase.auth().getRedirectResult().then((result: any) => {
          if (result && result.credential) {
            sessionStorage.setItem('auth-token', result.credential.idToken);

            const status = { activity: 'LOG IN', message: `${user.email} logged in to dashboard`, userId: user.email };
            this.addToStack(status);

          }
          if (sessionStorage.getItem('auth-token') === null) {
            const provider = new firebase.auth.OAuthProvider('microsoft.com');
            firebase.auth().currentUser.reauthenticateWithRedirect(provider);
          }
        });
        this.getImage(user.email, (callback) => {
          this.currentUser.imageUrl = callback;
        });

        firebase.auth().currentUser.getIdToken(true).then(() => {
          if (sessionStorage.getItem('auth-token')) {
            this.getUserData(this.currentUser.email);
            this.getAdvisorData(this.currentUser.email);
            this.getUserCourseData(this.currentUser.email);
          }
        });
      }
    });
  }

  /**
   * @description get user data based on user email from graphql service
   * @method getUserData
   * @param {string} email
   */ 
  getUserData(email: string) {
    this.graphqlService.getUserDetails(email).subscribe((content: any) => {
      this.currentUserDetails = content.data.user.info;
      this.currentUser.identifier = this.currentUserDetails.identifier.split('.')[1];
    });
  }

  /**
   * @description get advisor data based on user email from graphql service
   * @method getAdvisorData
   * @param {string} email
   */ 
  getAdvisorData(email: string) {
    this.graphqlService.getAdvisorDetails(email).subscribe((content: any) => {
      this.currentUserAdvisorDetails = content.data.user.academicAdvisor;
      this.getImage(this.currentUserAdvisorDetails.email, (callback) => {
        this.currentUserAdvisorDetails.imageUrl = callback;
      });
    });
  }

  /**
   * @description get user course data based on user email from graphql service
   * @method getUserCourseData
   * @param {string} email
   */ 
  getUserCourseData(email: string) {
    this.graphqlService.getUserCourses(email).subscribe((content: any) => {
      this.currentUserCourseDetails = content.data.user.classes;
      this.currentUserCourseDetails.forEach((element: any) => {
        element.isActive = this.isCourseActive(element.term.startDate);
        element.startDate = this.datePipe.transform(this.activationDate, 'MM/dd/yyyy');
        if (element.isActive) {
          element.url = environment.backBoardUrl + element.lmsClassId;
        }
      });
    });
  }

  /**
   * @description get image based on user email from user service
   * @method getImage
   * @param {any} email
   */ 
  getImage(email: string, callback) {
    this.userService.getImage(email, (responseData: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(responseData);
      reader.onload = () => {
        callback(reader.result);
      };
    }, (error: any) => {
      console.log(error);
    });
  }

  /**
   * @description open modal based on user selection
   * @method openPopupFunc
   * @param {any} $event
   */ 
  openPopupFunc($event) {
    this.openModal = $event;
  }

  /**
   * @description pop up action method
   * @method popupActionFunc
   * @param {any} $event
   */ 
  popupActionFunc($event) {
    if ($event.isOpen) {
      this[$event.action]();
    } else {
      this.openModal = $event.isOpen;
    }
  }

  /**
   * @description method for logging out
   * @method logout
   */ 
  logout() {
    if (this.currentUserDetails) {
      const status = { activity: 'LOG OUT', message: `${this.currentUserDetails.email} logged out`, userId: this.currentUserDetails.email };
      this.addToStack(status);
    }
    firebase.auth().signOut();
    window.location.assign(this.logoutUrl);
  }

  /**
   * @description method to display course if course is active
   * @method isCourseActive
   * @param {any} courseDate
   * @returns {boolean} true or false
   */ 
  isCourseActive(courseDate: any) {
    const daysAfter = 4;
    const easternTimeHoursOffset = 5;
    const dayToMilliseconds = 1000 * 60 * 60 * 24;
    const startDate = (Date.parse(courseDate) + (easternTimeHoursOffset * 60 * 60 * 1000)) - (daysAfter * dayToMilliseconds);
    this.activationDate = startDate;
    const currentDate: any = new Date();
    if (currentDate.getTime() < new Date(startDate).getTime()) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * @description add status data to stack
   * @method addToStack
   * @param {any} statusData
   */ 
  addToStack(statusData: any) {
    this.cloudService.postDataToStack(statusData, (response: any) => { }, (error: any) => { console.log(error); });
  }

}
