import gql from 'graphql-tag';

export const GET_USER_CLASSES = gql`query userClasses($email: String!) {
    user(email: $email) {
        classes {
            term {
                dateLastModified
                endDate
                parentSourcedId
                sourcedId
                startDate
                status
                title
                type
                parent {
                    dateLastModified
                    endDate
                    parentSourcedId
                    sourcedId
                    startDate
                    status
                    title
                    type
                }
            }
            school {
                boarding
                classification
                dateLastModified
                gender
                identifier
                name
                parentSourcedId
                sourcedId
                status
                type
            }
            course {
                courseCode
                dateLastModified
                description
                duration
                grade
                orgSourcedId
                schoolYearId
                sourcedId
                status
                subjects
                title
            }
            classCode
            classType
            courseSourcedId
            dateLastModified
            ext_laur_course_credits
            ext_laur_fulfillment_type
            grade
            lmsClassId
            location
            sourcedId
            status
            subjects
            termSourcedId
            title
            courseTitle
            finalGrade
            teachers {
                username
                userId
                givenName
                familyName
                role
                identifier
                email
                sms
                phone
            }
        }
    }
}`;

