<div class="pop-up-wrapper">
    <div class="popup-overlay"></div>
    <div class="pop-up-container">
        <div class="inner">
            <h2>Log Out</h2>
            <div>
                <hr class="yellow-hr">
            </div>

            <p class="description">
                <b>Are you sure you want to Logout?</b>
            </p>
            <button class="btn-approve" (click)="popupAction(true,'logout')">Yes</button>
            <button class="btn-cancel" (click)="popupAction(false, '')">No</button>
        </div>
    </div>
</div>