import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(public http: HttpClient) { }

  /**
   * @description get image
   * @method getImage
   * @param {string} emailData
   * @param {any} successCallback
   * @param {any} failureCallback
   * @returns image response
   */
    getImage(emailData: string, successCallback: any, failureCallback: any) {
        const url = environment.userImageUrl + emailData;
        this.http.get(url, { responseType: 'blob' }).subscribe(response => {
            successCallback(response);
        }, error => {
            failureCallback(error);
        });
    }


}
