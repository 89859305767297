import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit {

  @Output() popupFlag = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * @description pop up action
   * @method popupAction
   * @param {boolean} $event
   * @param {string} actionData
   * @returns void
   */ 
  popupAction($event: boolean, actionData: string) {
    const action = {
      isOpen: $event,
      action: actionData
    };
    this.popupFlag.emit(action) ;
  }
}
